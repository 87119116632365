import React from "react";
import { AppTimeline } from "./AppTimeline";

const EntranceFees = () => {
  return (
    <div className="bg-white-yale">
      <div className="container py-14 md:py-28 text-secondary text-center">
        <h2 className="title-secundary">Entrance Fees</h2>
        <p className="mt-8 font-sylfaen font-bold text-2xl">Daily Rates</p>
        <p className="mb-4 font-sylfaen text-2xl">
          (Grounds & Church Exhibits)
        </p>
        {/* <p className="mb-14 text-md xl:w-2/3 mx-auto">
          The Heritage Site is open to the public, but the Creighton House is
          closed for the 2023 season in order to update the heritage
          interpretation.
        </p> */}
        <AppTimeline
          className="gap-6 xl:gap-2"
          colorLine="bg-secondary"
          heigthLine="h-20"
          widthLine="w-[200px]"
          items={[
            [
              <div className="ml-auto lg:mx-auto w-fit">
                <i className="iconify text-2xl" data-icon="uil:user-nurse" />
              </div>,
              <p className="text-2xl">
                $12.00
                <span className="block font-normal normal-case text-base">
                  Adults
                </span>
              </p>,
            ],
            [
              <div className="ml-auto lg:mx-auto w-fit">
                <i className="iconify text-2xl" data-icon="uil:user-md" />
              </div>,
              <p className="text-2xl">
                $10.00
                <span className="block font-normal normal-case text-base">
                  Seniors (65+)
                </span>
              </p>,
            ],
            [
              <div className="ml-auto lg:mx-auto w-fit">
                <i className="iconify text-2xl" data-icon="uil:users-alt" />
              </div>,
              <p className="text-2xl">
                $10.00
                <span className="block font-normal normal-case text-base">
                  Students (with ID)
                </span>
              </p>,
            ],
            [
              <div className="ml-auto lg:mx-auto w-fit">
                <i className="iconify text-2xl" data-icon="uil:user" />
              </div>,
              <p className="text-2xl">
                $8.50
                <span className="block font-normal normal-case text-base">
                  Youth (13-17)
                </span>
              </p>,
            ],
            [
              <div className="ml-auto lg:mx-auto w-fit">
                <i className="iconify text-2xl" data-icon="uil:user" />
              </div>,
              <p className="text-2xl">
                $6.00
                <span className="block font-normal normal-case text-base">
                  Children (6-12)
                </span>
              </p>,
            ],
            [
              <div id="last-item" className="ml-auto lg:mx-auto w-fit">
                <i className="iconify text-2xl" data-icon="uil:kid" />
              </div>,
              <p className="text-2xl">
                FREE
                <span className="block font-normal normal-case text-base">
                  Under 6
                </span>
              </p>,
            ],
          ]}
        />

        <div className="mt-14 lg:mt-20 bg-primary rounded-lg w-fit mx-auto flex items-center gap-5 p-4">
          <p className="h-3 rounded-full aspect-square block bg-secondary" />
          <p className="font-bold">
            Groups of 10 or more receive a 20% off on entrance fees.
          </p>
          <p className="h-3 rounded-full aspect-square block bg-secondary" />
        </div>
        <p className="mt-14 lg:mt-20 xl:w-2/3 mx-auto">
          Your entrance fees help support the preservation and maintenance of
          the Yale Historic Site, ensuring it remains accessible for future
          generations.
        </p>
      </div>
    </div>
  );
};

export default EntranceFees;
