export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const validatePassword = (password) => {
  return String(password).match(
    /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&? "]).*$/,
  );
};

export const passwordValidateErrorMessage =
  "Password must be at least 8-chracters long, has one uppercase letter, one lowercase letter, and one special character!";

export const redirect = () => {
  if (!window.location.host.includes("localhost")) {
    if (!window.location.protocol.includes("s")) {
      window.location.replace(
        `https://${window.location.host}${
          window.location.pathname.length > 1
            ? `/${window.location.pathname}`
            : ""
        }`,
      );
    }
  }
};
