import React from "react";
import AppSlide from "./AppSlide";

const SiteShowcase = () => {
  return (
    <div className="bg-white-yale text-secondary">
      <div className="bg-white-yale text-secondary">
        <div className="mt-14 md:mt-28 flex flex-col xl:flex-row justify-end xl:gap-24 container items-end">
          <AppSlide className="w-full h-[449px]">
            <div
              className="bg-cover bg-center bg-no-repeat h-full w-full rounded-t-2xl rounded-br-2xl"
              style={{
                backgroundImage: `url(${require("../assets/img/home/the-st.john-the-divine.jpg")})`,
              }}
            />
            <div
              className="bg-cover bg-center bg-no-repeat h-full w-full rounded-t-2xl rounded-br-2xl"
              style={{
                backgroundImage: `url(${require("../assets/img/home/the-st.john-the-divine-1.jpg")})`,
              }}
            />
          </AppSlide>
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            className="xl:max-w-[746px]"
          >
            <h2 className="title-secundary my-8">
              The St. John The <span className="md:block">Divine Church</span>
            </h2>
            <p className="mb-8 md:mb-0">
              St. John the Divine church has stood for over a century and a
              half, bearing witness to the changing times and the story of a
              community that has grown around it. Initially built in 1863, the
              church was intended to be a beacon of civility and order amidst
              the rugged landscape of the miners' town it overlooked. Today, the
              church is a testament to the perseverance of its people, having
              undergone a significant restoration in 1953 and another smaller
              one in 2001. Yet, despite the passage of time, the original walls,
              rafters, and handmade nails have stood the test of time, still
              clearly visible to all who enter.
            </p>
            <p
              data-aos="zoom-in"
              data-aos-duration="2000"
              className="bg-primary h-0.5 mt-8"
            />
          </div>
        </div>
      </div>

      <div className="bg-white-yale text-secondary">
        <div className="mt-3 md:mt-8 flex flex-col xl:flex-row justify-end xl:gap-24 container items-end mb-10">
          <AppSlide className="xl:order-2 w-full h-[449px]">
            <div
              className="bg-cover bg-center bg-no-repeat h-full w-full rounded-t-2xl rounded-br-2xl"
              style={{
                backgroundImage: `url(${require("../assets/img/home/the-creighton-house-1.jpg")})`,
              }}
            />
            <div
              className="bg-cover bg-center bg-no-repeat h-full w-full rounded-t-2xl rounded-br-2xl"
              style={{
                backgroundImage: `url(${require("../assets/img/home/the-creighton-house-1.jpg")})`,
              }}
            />
          </AppSlide>
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            className="xl:max-w-[746px]"
          >
            <h2 className="title-secundary my-8">The Creighton House</h2>
            <p className="mb-8 md:mb-0">
              The Creighton House, a heritage building dating back to the 1870s,
              is transforming. Each room is being reimagined into a story-driven
              exhibition space aimed at engaging visitors of all ages. This
              renovation is a key initiative of the Forager Foundation at the
              Site, supported by the Province of British Columbia. The new
              exhibition will showcase the diverse histories of the communities
              that shaped this gold rush town, including the enduring Indigenous
              communities with millennia-old roots in the region. While parts of
              the museum are still under renovation, guided tours can be
              arranged regularly or upon request when accessing the museum.
            </p>
            <p
              data-aos="zoom-in"
              data-aos-duration="2000"
              className="bg-primary h-0.5 mt-8"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteShowcase;
