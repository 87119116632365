import React from "react";
export const AppTimeline = ({
  items,
  className,
  colorLine = "bg-white-yale",
  widthLine = "w-[168px]",
  heigthLine = "h-12",
}) => {
  return (
    <div
      className={`max-w-6xl mx-auto flex justify-between place-items-center flex-col xl:flex-row ${className}`}
    >
      {items.map((item, index) => (
        <div key={index} className="flex items-center xl:flex-col gap-6 w-fit">
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            className="w-[100px] xl:text-xl text-end lg:text-center"
          >
            {item[0]}
          </div>
          <div className="relative">
            <span
              className={`xl:hidden absolute left-1/2 -translate-x-1/2 w-[1px] ${colorLine} ${
                item[0].props.id === "last-item" ? "h-0" : heigthLine
              }`}
            />
            <span
              className={`hidden xl:block absolute -translate-y-1/2 h-0.5  ${colorLine} ${
                item[0].props.id === "last-item" ? "w-0" : widthLine
              }`}
            />
            <span className="block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-2 h-2 bg-primary aspect-square rounded-full" />
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="w-[150px] xl:w-[150px] font-sylfaen uppercase xl:text-2xl text-start xl:text-center"
          >
            {item[1]}
          </div>
        </div>
      ))}
    </div>
  );
};
