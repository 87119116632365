import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { redirect } from "./constants/helpers";

import HomeScreen from "./screen/HomeScreen";
import Renavigate from "./components/Renavigate";

const App = () => {
  useEffect(() => {
    Aos.init();
    redirect();
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="*" element={<Renavigate />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
