import React, { useEffect, useState } from "react";

const ScrollToTop = () => {
  const [showGoTopButton, setShowGoTopButton] = useState(false);

  const scrollTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setShowGoTopButton(document.documentElement.scrollTop > 460);
    });
  }, []);

  return (
    <button
      onClick={scrollTop}
      className={`with-transition duration-300 text-secondary bg-white hover:bg-primary px-3 h-[43px] shadow-lg fixed bottom-4 right-4 md:bottom-10 md:right-10 text-4xl z-20
     ${showGoTopButton ? "opacity-100" : "opacity-0 pointer-events-none"} `}
    >
      <i className="iconify text-lg" data-icon="ep:arrow-up-bold" />
    </button>
  );
};

export default ScrollToTop;
