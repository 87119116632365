import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { createContactMail } from "../actions/contactMailActions";
import { CONTACTMAIL_CREATE_RESET } from "../constants/contactMailConstants";

const Contact = () => {
    const dispatch = useDispatch();

    const contactMailCreate = useSelector((state) => state.contactMailCreate);
  const { success } = contactMailCreate;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const subjectHandler = (e) => {
    setSubject(e.target.value);
  };

  const messageHandler = (e) => {
    setMessage(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      name.length > 0 &&
      email.length > 0 &&
      subject.length > 0 &&
      message.length > 0
    ) {
      const contactMail = {
        name,
        email,
        subject,
        message,
      };

      dispatch(createContactMail(contactMail));
    }
  };

  useEffect(() => {
    if (success) {
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      alert("Email sent successfully! Thank you for your interest!");

      setTimeout(() => {
        dispatch({ type: CONTACTMAIL_CREATE_RESET });
      }, 7000);
    }
  }, [success, dispatch]);

  return (
    <div className="bg-secondary">
      <div id="contact" className="container text-white-yale">
        <div className="grid md:grid-cols-2">
          <div className="relative">
            <h2 className="title-secundary mt-14 lg:mt-28 mb-8">Contact</h2>
            <p className="font-bold tracking-wide text-lg">
              STAY CONNECTED WITH US! 
            </p>
            <p className="my-5 md:pr-5">
              The Yale Historic Site is committed to creating a hub for the
              community and a place for visitors to explore the many stories
              this small community has to share. We value your interest and
              support in preserving our heritage site. If you have inquiries,
              want to explore volunteer opportunities, or wish to collaborate
              with us to celebrate our shared cultural legacy, we invite you to
              contact us. Our dedicated team is eager to answer your questions
              and guide you toward engaging experiences. Reach out to us using
              the contact details below, and let's embark on a journey of
              discovery together. 
            </p>

            <div>
              <p className="mb-5">
                <span className="font-bold">Address: </span>
                <span className="tracking-normal mt-1 font-normal block">
                  31187 Douglas St, Yale, BC V0K 2S0
                </span>
              </p>
              <p>
                <span className="font-bold">Phone: </span>
                <span className="tracking-normal mt-1 font-normal block">
                  +1 604 924 7632
                </span>
              </p>
              <p className="mt-5">
                <span className="font-bold">General Enquiries: </span>
                <span className="tracking-normal mt-1 font-normal block">
                  info@historicyale.com
                </span>
              </p>
            </div>
            <div className="flex justify-center md:justify-start gap-5 mt-14">
              <Link
                target="_blank"
                to="https://www.facebook.com/HistoricYalePHS"
              >
                <i
                  className="iconify text-3xl hover:scale-125 with-transition"
                  data-icon="basil:facebook-solid"
                />
              </Link>
              <Link target="_blank" to="http://twitter.com/historicyalephs">
                <i
                  className="iconify text-3xl hover:scale-125 with-transition"
                  data-icon="mdi:twitter"
                />
              </Link>
              <Link target="_blank" to="https://instagram.com/historicyalephs">
                <i
                  className="iconify text-3xl hover:scale-125 with-transition"
                  data-icon="mdi:instagram"
                />
              </Link>
              <Link
                target="_blank"
                to="https://ca.linkedin.com/showcase/historicyalephs"
              >
                <i
                  className="iconify text-3xl hover:scale-125 with-transition"
                  data-icon="mdi:linkedin"
                />
              </Link>
              <Link
                target="_blank"
                to="https://www.tiktok.com/@historicyalephs"
              >
                <i
                  className="iconify text-3xl hover:scale-125 with-transition"
                  data-icon="ic:baseline-tiktok"
                />
              </Link>
            </div>
          </div>
          <div>
            <h2 className="md:pl-10 title-secundary capitalize mt-14 lg:mt-28 mb-8">
              Get in touch
            </h2>
            <p className="md:pl-10">
              Fields marked with an <span className="text-primary">*</span> are
              required
            </p>
            <form
              className="md:pl-10 mt-5 grid grid-cols-2 gap-5"
              autoComplete="off"
              onSubmit={submitHandler}
            >
              <label className="col-span-2 md:col-span-1">
                Name <span className="text-primary">*</span>
                <input
                  type="text"
                  name="name"
                  className="h-14 bg-transparent rounded-lg border-[1px] w-full mt-2 p-2"
                  required
                  value={name}
                  onChange={nameHandler}
                />
              </label>
              <label className="col-span-2 md:col-span-1">
                Email Address <span className="text-primary">*</span>
                <input
                  type="email"
                  name="email"
                  className="h-14 bg-transparent rounded-lg border-[1px] w-full mt-2 p-2"
                  required
                  value={email}
                  onChange={emailHandler}
                />
              </label>
              <label className="col-span-2">
                Subject <span className="text-primary">*</span>
                <input
                  type="text"
                  name="subject"
                  className="h-14 bg-transparent rounded-lg border-[1px] w-full mt-2 p-2"
                  required
                  value={subject}
                  onChange={subjectHandler}
                />
              </label>
              <label className="col-span-2">
                Message <span className="text-primary">*</span>
                <textarea
                  name="message"
                  className="h-32 md:h-[250px] xl:h-48 bg-transparent rounded-lg border-[1px] w-full mt-2 p-2"
                  required
                  value={message}
                  onChange={messageHandler}
                />
              </label>
              <input
                type="submit"
                value="Submit"
                className="col-span-2 lg:mt-2 with-transition hover:bg-salmon 
             w-full md:w-1/4 py-3 font-sylfaen text-secondary bg-primary hover:bg-white-yale rounded-full mx-auto md:ml-0 cursor-pointer"
                onSubmit={submitHandler}
              />
            </form>
            <img
              src={require("../assets/logos/logo-peh-white-2.png")}
              alt=""
              className="w-1/2 mx-auto mt-14 md:hidden"
            />
          </div>
        </div>
        <div className="col-span-full mt-14 md:mt-28 pb-5">
          <span className="block w-full border-t-[1px] mb-2 mx-auto" />
          <p className="lg:flex lg:justify-between px-3 lg:px-0 text-xs text-center">
            Copyright © {new Date().getFullYear()}, Forager Foundation
            <span className="block">
              website created by
              <span className="font-semibold tracking-wide">
                {" "}
                forager | <span className="text-orange-digital">digital</span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
