import React from "react";

import AppModal from "./AppModal";

const EventDetail = ({ event }) => {
  return (
    <AppModal
      activator={
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="cursor-pointer max-w-[774px] m-auto mb-16"
        >
          <div
            className="h-[254px] md:mt-12 md:max-w-[774px] md:mx-auto bg-cover bg-no-repeat bg-center rounded-t-3xl hover:border-8 hover:border-primary with-transition"
            style={{
              backgroundImage: `url(${require(`../assets/img/home/bg-${event.slug}.png`)})`,
            }}
          />
          <div className="bg-primary text-secondary pr-4 md:pr-12 py-6 overflow-hidden md:max-w-[774px] md:mx-auto relative pl-24 md:pl-36 flex flex-col">
            <div className="text-start">
              <p className="text-4xl font-sylfaen">
                {event.title.substr(0, 20)}...
              </p>
              <div className="lg:flex lg:mt-5 ">
                <p className="font-bold">{event.price}</p>
                <p className="mt-4 lg:mt-0 lg:pl-8">{event.date}</p>
              </div>
            </div>
            <img
              src={require("../assets/logos/isotipo.png")}
              alt=""
              className="absolute w-20 lg:w-[132px] -left-3 xl:-left-4 top-1/2 -translate-y-1/2"
            />
          </div>
        </div>
      }
      className="lg:max-w-none lg:w-[50%] max-h-screen"
    >
      <div className="bg-white grid justify-center pt-14 md:pt-0 pb-3">
        <div>
          <div
            className="grid place-items-center w-full h-44 md:h-[306px] mb-6 bg-cover bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${require(`../assets/img/home/bg-${event.slug}.png`)})`,
            }}
          />
        </div>
        <div className="px-4 lg:px-14">
          <h4 className="text-secondary text-4xl text-center lg:text-start font-sylfaen">
            {event.title}
          </h4>
          <span className="block bg-primary w-full lg:w-[310px] mx-auto lg:mx-0 h-[1px] my-1" />
          <p className="mb-5 lg:text-start">
            {event.date} <span className="font-bold">{event.price}</span>
          </p>
          <div className="pb-8">
            <p className="lg:text-start">{event.description}</p>
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default EventDetail;
