import React, { useState, useEffect } from "react";

const AppSlide = ({ children, className, time = 4000 }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % children.length);
    }, time);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, [children]);

  return (
    <div className={`overflow-hidden relative ${className}`}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          className: `absolute top-0 left-0 transition-all ease-in-out duration-[6000ms]
                      ${child.props.className}
                      ${index === activeIndex ? "opacity-1" : "opacity-0"}`,
        }),
      )}
    </div>
  );
};

export default AppSlide;
