import React from "react";

const GetInvolved = () => {
  return (
    <div className="bg-primary text-secondary grid md:grid-cols-2 md:items-center">
      <div className="hidden md:block" />
      <div
        className="md:order-2 h-[257px] md:h-[475px] bg-cover bg-no-repeat bg-center"
        style={{
          backgroundImage: `url(${require("../assets/img/home/get-involved.jpg")})`,
        }}
      />
      <div className="md:absolute w-full">
        <div className="container grid grid-cols-2">
          <div className="col-span-2 md:col-span-1">
            <h2 className="mt-14 lg:mt-0 title-secundary">Get Involved</h2>
            <p className="mt-8 mb-14 lg:mb-0 md:pr-7">
              Join us in preserving history! In addition to visiting the Yale
              Historic Site, you can get involved and make a difference. Whether
              you are passionate about heritage preservation, volunteer work,
              contributing to our programs, or making the community a better
              place, we welcome your support. Contact us to explore the various
              opportunities available, and we'll be delighted to assist you in
              becoming a valued community member.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInvolved;
