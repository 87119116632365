import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Renavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.pathname !== "/") {
      navigate("/");
    }
  }, [location, navigate]);

  return null;
};

export default Renavigate;
