import React from "react";
import { useBreakpoints } from "../hooks/useBreackPoint";

import { events } from "../data/data";

import AppCarousel from "./AppCarousel";
import EventDetail from "./EventDetail";

const UpcomingEvents = () => {
  const { md } = useBreakpoints();

  return (
    <div className="bg-white-yale text-secondary py-14 md:py-28">
      <div className="md:text-center mx-auto">
        <h2 className="container title-secundary mb-8 ">Event Updates Coming Soon</h2>
        {/* <div>
          <AppCarousel withArrows={md} center>
            <div className="w-full" />
            {events.map((event, i) => (
              <EventDetail key={i} event={event} />
            ))}
            <div className="w-24" />
          </AppCarousel>
        </div> */}
      </div>
    </div>
  );
};

export default UpcomingEvents;
