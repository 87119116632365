import React from "react";

import { useBreakpoints } from "../hooks/useBreackPoint";

import AppSlide from "../components/AppSlide";

const Landing = () => {
  const { md } = useBreakpoints();

  const scrollToContact = () => {
    window.scrollTo({ top: 15800, behavior: "smooth" });
  };

  return (
    <div className="bg-secondary">
      <div className="container">
        <div className="flex pt-6 justify-center md:justify-between">
          <img
            src={require("../assets/logos/logo-peh-white-large.png")}
            alt="Logo"
            className="w-1/2 md:w-52 mb-6"
          />
        </div>
      </div>
      <p
        data-aos="zoom-in"
        data-aos-duration="1000"
        className="h-[1px] bg-white-yale hidden lg:block lg:w-[51.8%] xl:w-[50%]"
      />
      <div className="bg-secondary grid md:grid-cols-2 md:gap-10 xl:gap-0">
        <div className="hidden md:block" />
        <div className="md:order-2 h-[257px] md:h-[740px] bg-cover bg-no-repeat bg-center">
          <AppSlide className="h-[257px] md:h-[852px] md:-mt-28 ">
            <img
              src={require("../assets/img/home/banner-mobile-1.jpg")}
              alt=""
              className="h-full w-full object-cover"
            />
            <img
              src={require(md
                ? "../assets/img/home/banner-mobile-2.jpg"
                : "../assets/img/home/banner-mobile-2.jpg")}
              alt=""
              className="h-full w-full object-cover"
            />
            <img
              src={require(md
                ? "../assets/img/home/banner-mobile-3.jpg"
                : "../assets/img/home/banner-mobile-3.jpg")}
              alt=""
              className="h-full w-full object-cover"
            />
          </AppSlide>
        </div>

        <div className="md:absolute w-full">
          <div className="container grid grid-cols-2 lg:-mt-28">
            <div className="col-span-2 md:col-span-1">
              <h1 className="font-sylfaen font-title text-center md:text-start text-primary mt-8 mb-5 lg:mt-40 lg:mb-12">
                Boomtown <span className="xl:block">On The Fraser</span>
              </h1>
              <div className="container md:px-0 lg:mx-0 md:text-start lg:w-2/3 xl:w-[90%] text-white-yale text-center mb-14 lg:mb-12">
                <p className="mb-5">
                  “The <span className="font-bold">Yale Historic Site </span>
                  allows visitors to explore the stories that shaped history in
                  unexpected ways.”
                </p>
                <p>
                  The Yale Historic Site is proud to be located within the
                  traditional, ancestral, and unceded territory of the
                  Nlaka’pamux and Stó:lō peoples.
                </p>
              </div>
              <button
                onClick={scrollToContact}
                className="hidden md:block font-sylfaen with-transition h-fit py-3 px-9 rounded-full z-20 bg-primary hover:bg-white-yale text-secondary"
              >
                Contact
              </button>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="2000"
                className="hidden xl:flex gap-2 items-center absolute -bottom-[72px]"
              >
                <i
                  className="iconify text-2xl text-primary"
                  data-icon="mingcute:mouse-fill"
                />
                <p className="font-normal text-xs text-white-yale">
                  Scroll to see more
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
