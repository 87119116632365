import React from "react";

const About = () => {
  return (
    <div className="bg-primary py-14 md:py-28">
      <div className="container">
        <img
          src={require("../assets/logos/isotipo.png")}
          alt="Logo Point Ellice House "
          className="mx-auto w-20 mb-8"
        />
        <p className="text-secondary text-center md:w-2/3 md:mx-auto">
          Set along the banks of the mighty Fraser River, the Yale Historic Site
          allows visitors to explore the stories that shaped history in
          unexpected ways. Stroll along the pathways to experience the tranquil
          surroundings under the shade of the historic copper beech tree, wander
          through the pews of the St. John the Divine Church, and visit the
          Creighton House as it undergoes a reimaging of how it shares the
          stories of the community. With new exhibits and interactive features,
          this space is set to become a great draw for visitors travelling
          through the Fraser Canyon. Just a bit further down the road, visitors
          can also take a moment to relax in the gardens of the On Lee property.
          The site of a store and home of a prominent member of the Chinese
          community in Yale is off the beaten path in Yale, but well worth a
          visit to understand the Chinese community's contribution.
        </p>
      </div>
    </div>
  );
};

export default About;
