import React from "react";

import Landing from "../components/Landing";
import About from "../components/About";
import ScrollToTop from "../components/ScrollToTop";
import SiteShowcase from "../components/SiteShowcase";
import UpcomingEvents from "../components/UpcomingEvents";
import OpeningTiming from "../components/OpeningTiming";
import EntranceFees from "../components/EntranceFees";
import GetInvolved from "../components/GetInvolved";
import Contact from "../components/Contact";

const HomeScreen = () => {
  return (
    <div className="relative">
      <Landing />
      <About />
      <ScrollToTop />
      <SiteShowcase />
      <UpcomingEvents />
      <OpeningTiming />
      <EntranceFees />
      <GetInvolved />
      <Contact />
    </div>
  );
};

export default HomeScreen;
